<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">

                    <!--<div><router-link to="/Admin/store/form"><el-button type="primary" icon="el-icon-plus">添加</el-button></router-link></div>-->
                    <div><el-input v-model="where.phone" placeholder="请输入手机号"></el-input></div>
                    <div><el-date-picker format="yyyy-MM-dd HH:mm" v-model="where.times" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker></div>
                   <!-- <div style="width:140px;">
                        <el-select v-model="where.F_TI_STATE" placeholder="是否禁用">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="启用中" value="1"></el-option>
                            <el-option label="已禁用" value="2"></el-option>
                        </el-select>
                    </div>-->
                    <!--<div style="width:140px;">
                        <el-select v-model="where.shouqmsfjh" placeholder="是否激活">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="已激活" value="1"></el-option>
                            <el-option label="未激活" value="2"></el-option>
                        </el-select>
                    </div>-->

                    <div><el-button icon="el-icon-search" @click="get_store_list()">条件筛选</el-button></div>
                </div>

                 <!--<div class="admin_main_block_right">
                    <div><el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button></div>
                </div>-->
            </div>

            <div class="admin_table_main">
                <el-table :data="list" @selection-change="handleSelectionChange" >
                    <el-table-column type="selection"></el-table-column>
                    <el-table-column prop="id" label="#" fixed="left" width="80px"></el-table-column>
<!--                    <el-table-column prop="phone" label="用户昵称" >
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt>
                                    <el-popover placement="left" trigger="click" width="800">
                                        <img :src="scope.row.avatar||require('@/assets/default_pic.png')" width="100%" />
                                        <img
                                                slot="reference"
                                                :src="scope.row.avatar||require('@/assets/default_pic.png')"
                                                :alt="scope.row.avatar"
                                                style="max-height: 50px;max-width: 50px; padding: 5px"
                                        />
                                    </el-popover>
                                    </dt>
                            </dl>
                        </template>
                    </el-table-column>-->
                  <el-table-column prop="xingm" label="姓名" width="150px"></el-table-column>
                    <el-table-column prop="phone" label="手机号" ></el-table-column>

                  <el-table-column label="身份证正面" >
                    <template slot-scope="scope">
                      <dl class="table_dl">
                        <dt>
                          <el-popover placement="left" trigger="click" width="600">
                            <img :src="scope.row.shenfzzm||require('@/assets/default_pic.png')"
                                 width="100%"/>
                            <img
                                slot="reference"
                                :src="scope.row.shenfzzm||require('@/assets/default_pic.png')"
                                :alt="scope.row.shenfzzm"
                                style="max-height: 60px;max-width: 60px; padding: 5px"
                            />
                          </el-popover>
                        </dt>
                      </dl>
                    </template>
                  </el-table-column>
                  <el-table-column label="身份证反面" >
                    <template slot-scope="scope">
                      <dl class="table_dl">
                        <dt>
                          <el-popover placement="left" trigger="click" width="600">
                            <img :src="scope.row.shenfzfm||require('@/assets/default_pic.png')"
                                 width="100%"/>
                            <img
                                slot="reference"
                                :src="scope.row.shenfzfm||require('@/assets/default_pic.png')"
                                :alt="scope.row.shenfzfm"
                                style="max-height: 60px;max-width: 60px; padding: 5px"
                            />
                          </el-popover>
                        </dt>
                      </dl>
                    </template>
                  </el-table-column>


                  <el-table-column label="房产证" >
                    <template slot-scope="scope">
                      <dl class="table_dl">
                        <dt>
                          <el-popover placement="left" trigger="click" width="600">
                            <img :src="scope.row.fangcz||require('@/assets/default_pic.png')"
                                 width="100%"/>
                            <img
                                slot="reference"
                                :src="scope.row.fangcz||require('@/assets/default_pic.png')"
                                :alt="scope.row.fangcz"
                                style="max-height: 60px;max-width: 60px; padding: 5px"
                            />
                          </el-popover>
                        </dt>
                      </dl>
                    </template>
                  </el-table-column>

                  <el-table-column label="合同凭据" >
                    <template slot-scope="scope">
                      <dl class="table_dl">
                        <dt>
                          <el-popover placement="left" trigger="click" width="600">
                            <img :src="scope.row.hetpj||require('@/assets/default_pic.png')"
                                 width="100%"/>
                            <img
                                slot="reference"
                                :src="scope.row.hetpj||require('@/assets/default_pic.png')"
                                :alt="scope.row.hetpj"
                                style="max-height: 60px;max-width: 60px; padding: 5px"
                            />
                          </el-popover>
                        </dt>
                      </dl>
                    </template>
                  </el-table-column>



                    <el-table-column prop="login_time" label="注册时间">
                        <template slot-scope="scope">
                            <div v-if="scope.row.login_time<=0"> - </div>
                            <div v-else>{{scope.row.login_time|formatDate}}</div>
                        </template>
                    </el-table-column>

                  <el-table-column label="审核状态" >
                    <template slot-scope="scope">
                      <el-tag type="gray" v-if="scope.row.shenhzt==1">审核通过</el-tag>
                      <el-tag type="success" v-else-if="scope.row.shenhzt==2">审核拒绝</el-tag>
                      <el-tag type="warning" v-else-if="scope.row.shenhzt==3">审核中</el-tag>
                      <el-tag type="warning" v-else-if="scope.row.shenhzt==4">待审核</el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" fixed="right" width="220px">
                    <template slot-scope="scope">
                      <el-button icon="el-icon-edit" @click="verify_click(scope.row.id)">审核</el-button>
                      <!--<el-button icon="el-icon-tickets" @click="read_store_info(scope.row)">查看</el-button>-->
                      <!-- <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> -->
                    </template>
                  </el-table-column>

                    <!--<el-table-column prop="winxskm" label="微信收款码" width="200px">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt>
                                    <el-popover placement="left" trigger="click" width="800">
                                        <img :src="scope.row.winxskm||require('@/assets/default_pic.png')" width="100%" />
                                        <img
                                                slot="reference"
                                                :src="scope.row.winxskm||require('@/assets/default_pic.png')"
                                                :alt="scope.row.winxskm"
                                                style="max-height: 50px;max-width: 50px; padding: 5px"
                                        />
                                    </el-popover>
                                </dt>
                            </dl>
                        </template>
                    </el-table-column>
                    <el-table-column prop="zhifbskm" label="支付宝收款码" >
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt>
                                    <el-popover placement="left" trigger="click" width="800">
                                        <img :src="scope.row.zhifbskm||require('@/assets/default_pic.png')" width="100%" />
                                        <img
                                                slot="reference"
                                                :src="scope.row.zhifbskm||require('@/assets/default_pic.png')"
                                                :alt="scope.row.zhifbskm"
                                                style="max-height: 50px;max-width: 50px; padding: 5px"
                                        />
                                    </el-popover>
                                </dt>
                            </dl>
                        </template>
                    </el-table-column>
                    <el-table-column prop="phone" label="银行卡号" width="260px">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dd class="table_dl_dd_all_30">银行名称： {{ scope.row.yinhmc }}</dd>
                                <dd class="table_dl_dd_all_30">银行卡号： {{ scope.row.yinhkh }}</dd>
                                <dd class="table_dl_dd_all_30">支行名称： {{ scope.row.zhihmc }}</dd>
                            </dl>
                        </template>
                    </el-table-column>-->

                    <!--<el-table-column label="禁用状态">
                        <template slot-scope="scope">
                            <div :class="scope.row.F_TI_STATE==1?'green_round':'gray_round'" @click="state_status(scope.row.id)"></div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="shouqm" label="授权码"></el-table-column>
                    <el-table-column label="是否激活">
                        <template slot-scope="scope">
                            <div v-if="scope.row.shouqmsfjh==1"  style="color:green;">已激活</div>
                            <div v-else class="color:gray" >未激活</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="jihsj" label="激活时间">
                        <template slot-scope="scope">
                            <div v-if="scope.row.jihsj<=0"> - </div>
                            <div v-else>{{scope.row.jihsj|formatDate}}</div>
                        </template>
                    </el-table-column>-->
                    <!--<el-table-column label="VIP会员">
                        <template slot-scope="scope">
                            <div :class="scope.row.F_TI_HUIY==1?'green_round':'gray_round'" @click="huiy_status(scope.row.id)"></div>
                        </template>
                    </el-table-column>
                    <el-table-column label="SVIP会员">
                        <template slot-scope="scope">
                            <div :class="scope.row.F_TI_SVIPHY==1?'green_round':'gray_round'" @click="sviphy_status(scope.row.id)"></div>
                        </template>
                    </el-table-column>-->
                    <!--<el-table-column label="操作" fixed="right" width="280px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-tickets" @click="chongz(scope.row.id)">手机切换重置</el-button>
                            <el-button style="margin-left:20px;" icon="el-icon-edit" @click="$router.push({name:'store_form',params:{id:scope.row.id}})">编辑</el-button>
                            &lt;!&ndash;<el-button icon="el-icon-tickets" @click="read_store_info(scope.row)">查看</el-button>&ndash;&gt;
                            &lt;!&ndash; <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> &ndash;&gt;
                        </template>
                    </el-table-column>-->
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination @current-change="current_change" background layout="prev, pager, next,jumper,total" :total="total_data" :page-size="page_size" :current-page="current_page"></el-pagination>
                </div>
            </div>
        </div>

        <el-dialog title="提示" :visible.sync="dialogVisible" width="50%">
            <ul class="store_list_ul">
                <li>
                    <h4>昵称:</h4>{{info.nickname|'-'}}
                </li>
                <li>
                    <h4>头像:</h4><el-image style="width: 60px; height: 60px" :src="info.store_logo"></el-image>
                </li>
                <!--<li>
                    <h4>入驻公司:</h4>{{info.store_company_name|'-'}}-{{info.area_info}}-{{info.store_address}}
                </li>
                <li>
                    <h4>营业执照号码:</h4>{{info.legal_person|'-'}}-{{info.business_license_no|'-'}}
                </li>-->
                <li>
                    <h4>身份证:</h4><el-image :preview-src-list="read_pic(info.business_license)" style="width: 60px; height: 60px" :src="info.business_license"></el-image>-<el-image :preview-src-list="read_pic(info.id_card)" style="width: 60px; height: 60px" :src="info.id_card"></el-image>
                </li>

                <li>
                    <h4>身份证号码:</h4>{{info.id_card_no|'-'}}
                </li>
                <li>
                    <h4>手机号:</h4><!--{{info.emergency_contact}}- -->{{info.emergency_contact_phone}}
                </li>
                <li>
                    <h4>禁用状态:</h4><el-radio v-model="info.store_status" :label="0">关闭</el-radio>
                    <el-radio v-model="info.store_status" :label="1">开启</el-radio>
                </li>
                <li v-if="info.store_status==0">
                    <h4>禁用原因:</h4><el-input type="textarea" placeholder="关闭原因！" v-model="info.store_close_info"></el-input>
                </li>
                <!--<li v-if="info.store_verify==0">
                    <h4>店铺审核:</h4>
                    <el-radio v-model="store_verify" :label="0">等待审核(忽略)</el-radio>
                    <el-radio v-model="store_verify" :label="1">同意</el-radio>
                    <el-radio v-model="store_verify" :label="2">拒绝</el-radio>
                </li>-->

            </ul>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">关 闭</el-button>
                <el-button type="primary" @click="update_store()">确 定</el-button>
            </span>
            </el-dialog>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          list:[],
          total_data:0, // 总条数
          page_size:20,
          current_page:1,
          select_id:'',
          dialogVisible:false,
          info:{},
          store_verify:0,
          where:{
              times:[],
              phone:''
          },
      };
    },
    watch: {},
    computed: {},
    methods: {
      verify_click: function (id) {
        this.$confirm('此操作将控制是否通过审核, 是否继续?', '提示', {
          confirmButtonText: '同意',
          cancelButtonText: '拒绝',
          type: 'info'
        }).then(() => {
          this.$post(this.$api.xianxddVerifyChange, {id: id, type: 2}).then(() => {
            this.$message.success('成功审核');
            this.get_store_list();
          });

        }).catch(() => {
          this.$post(this.$api.xianxddVerifyChange, {id: id, type: 3}).then(() => {
            this.$message.info('已拒绝');
            this.get_store_list();
          });

        });
      },
        handleSelectionChange:function(e){
            let ids = [];
            e.forEach(v => {
                ids.push(v.id);
            });
            this.select_id = ids.join(',');
        },
        get_store_list:function(){
            let where = {};
            where.page = this.current_page;
            where.params = this.where;
            this.$get(this.$api.getStoreList,where).then(res=>{
                this.page_size = res.data.per_page;
                this.total_data = res.data.total;
                this.list = res.data.data;
                this.current_page = res.data.current_page;
            })
        },

        read_store_info(e){
            this.info = e;
            this.store_verify = this.info.store_verify;
            this.dialogVisible=true;
        },
        read_pic(e){
            return [e];
        },
        update_store:function(){
            if(this.info.store_status==0 && this.info.store_close_info == ''){
                return this.$message.error('请填写关闭店铺的原因！');
            }
            this.info.store_verify = this.store_verify;
            /*this.$post(this.$api.StorePass,this.info).then(res=>{
                this.$message.success('修改成功');
                this.dialogVisible = false;
                this.get_store_list();
            });*/
        },
        // 分页改变
        current_change:function(e){
            this.current_page = e;
            this.get_store_list();
        },
        chongz:function(id){
            if(this.$isEmpty(id)){
                return this.$message.error('请先选择重置的对象');
            }
            this.$post(this.$api.huiyStoretStatus,{id:id}).then(res=>{
                if(res.code==200){
                    this.$message.success('操作成功');
                }else{
                    this.$message.success('操作失败');
                }
                this.get_store_list();
            });
        },
        // 删除处理
        del:function(id){
            let _this = this;
            if(this.$isEmpty(id)){
                return this.$message.error('请先选择删除的对象');
            }
            this.$post(this.$api.delStore,{id:id}).then(function(res){
                if(res.code == 200){
                    _this.get_store_list();
                    return _this.$message.success("删除成功");
                }else{
                    return _this.$message.error("删除失败！"+res.msg);
                }
            });
        },
        // 指定ID修改状态
        state_status:function(id){
            this.$post(this.$api.stateStoretStatus,{id:id}).then(res=>{
                if(res.code==200){
                    this.$message.success('操作成功');
                }else{
                    this.$message.success('操作失败');
                }
                this.get_store_list();
            });
        },
        // 指定ID修改状态
        huiy_status:function(id){
            this.$post(this.$api.huiyStoretStatus,{id:id}).then(res=>{
                if(res.code==200){
                    this.$message.success('操作成功');
                }else{
                    this.$message.success('操作失败');
                }
                this.get_store_list();
            });
        },
        // 指定ID修改状态
        sviphy_status:function(id){
            this.$post(this.$api.sviphyStoretStatus,{id:id}).then(res=>{
                if(res.code==200){
                    this.$message.success('操作成功');
                }else{
                    this.$message.success('操作失败');
                }
                this.get_store_list();
            });
        }
    },
    created() {
        this.get_store_list();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>
.store_list_ul li{
    border-bottom: 1px solid #efefef;
    margin:0 0 30px 0;
    padding-bottom: 15px;
    color:#666;
    h4{
        margin-bottom: 15px;
        color:#333;
    }
}
</style>
